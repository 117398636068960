import React, {useContext} from "react";
import {GridOverlay, GridOverlayProps} from "@mui/x-data-grid";
import {Container, Grid, IconButton, Typography} from "@mui/material";
import {useErrorContext} from "../Routes/Private/Home/Home";
import CancelIcon from '@mui/icons-material/Cancel';

function ErrorOverlay(props: GridOverlayProps & { allowAdd: boolean }) {
  const {error, setError} = useContext(useErrorContext);
  return (
    <GridOverlay>
      <Container>
        <Grid container direction="column" justifyContent="center" alignContent="center">
          <Grid item>
            <Typography align="center">
            <IconButton color="error" size="small">
                <CancelIcon  fontSize="large"/>
              </IconButton>
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="center" variant="h4">
              Errore
            </Typography>
          </Grid>
          <Grid item>
            <Typography  align="center" color="text.secondary">
              <code>{error}</code>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </GridOverlay>
  );
}

export default ErrorOverlay;
