import axios from "axios";
import {
  ApplicationUser,
  Cliente,
  Commessa, CommessaProdotto,
  Mansione,
  Mezzo,
  Nave,
  Personale,
  Prodotto,
  TurnoLavoro, TurnoLavoroAttivita,
  TurnoLavoroDettaglio
} from "../entities/interfaces";

export const ships = axios.create({
  baseURL: "https://93.56.107.190:453/Nave/",
});
export const defaultShips: Nave[] = [];
export const defaultShip: Nave = {};

export const orders = axios.create({
  baseURL: "https://93.56.107.190:453/Commessa/",
});
export const defaultOrders: Commessa[] = [];
export const defaultOrder: Commessa = {};

export const machineries = axios.create({
  baseURL: "https://93.56.107.190:453/Mezzo/",
});
export const defaultMachineries: Mezzo[] = [];
export const defaultMachinery: Mezzo = {};

export const employees = axios.create({
  baseURL: "https://93.56.107.190:453/Personale/",
});
export const defaultEmployees: Personale[] = [];
export const defaultEmployee: Personale = {};

export const goods = axios.create({
  baseURL: "https://93.56.107.190:453/Prodotto/",
});
export const defaultGoods: Prodotto[] = [];
export const defaultGood: Prodotto = {};

export const tasks = axios.create({
  baseURL: "https://93.56.107.190:453/Mansione/",
});
export const defaultTasks: Mansione[] = [];
export const defaultTask: Mansione = {};

export const clients = axios.create({
  baseURL: "https://93.56.107.190:453/Cliente/",
});
export const defaultClients: Cliente[] = [];
export const defaultClient: Cliente = {};

export const shifts = axios.create({
  baseURL: "https://93.56.107.190:453/TurnoLavoro/",
});
export const defaultShifts: TurnoLavoro[] = [];
export const defaultShift: TurnoLavoro = {};
export const defaultShiftDetails: TurnoLavoroDettaglio = {};
export const defaultShiftActivity: TurnoLavoroAttivita = {};






export const userName = "amministratore";