import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from "./App/Routes/Routes";
import Theme from "./App/Theme/theme";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {Backdrop, LinearProgress} from "@mui/material";

const Fallback = () => {
  return (
      <LinearProgress color="primary"/>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Theme>
        <Suspense fallback={<Fallback/>}>
          <Routes/>
        </Suspense>
      </Theme>
    </LocalizationProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

