// @ts-ignore
import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import { useAddDialogContext } from '../Routes/Private/Home/Home';
import ShiftDetailsPage from "../Routes/Private/ShiftsPage/ShiftDetailsPage";
import OrderDetailsPage from "../Routes/Private/OrdersPage/OrderDetailsPage";
const PageFrame = React.lazy(() => import("../PageFrame/PageFrame"));
const ShipDetailsPage = React.lazy(()=> import("../Routes/Private/ShipsPage/ShipDetailsPage"))

type PageParamsType = {
  pagePath: string;
};

function DetailsPage() {
  const {pagePath} = useParams<PageParamsType>();
  const [openAddDialog, setOpenAddDialog] = useState(false)
  const addDialogValue = {openAddDialog, setOpenAddDialog}

  const getPage = (pagePath: string) => {
    switch (pagePath) {
      case "navi":
        return <ShipDetailsPage/>
      case "commesse":
        return <OrderDetailsPage/>
      case "turni":
        return <ShiftDetailsPage/>
    }
  }
  return (
    <PageFrame>
      <useAddDialogContext.Provider value={addDialogValue}>
      {getPage(pagePath)}
      </useAddDialogContext.Provider>
    </PageFrame>
  );
}

export default DetailsPage;

