// @ts-ignore
import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import ShiftsPage from "../ShiftsPage/ShiftsPage";

const PageFrame = React.lazy(() => import("../../../PageFrame/PageFrame"));
const DashboardPage = React.lazy(() => import("../DashboardPage/DashboardPage"));
const OrdersPage = React.lazy(() => import("../OrdersPage/OrdersPage"));
const ClientsPage = React.lazy(() => import("../ClientsPage/ClientsPage"));
const ShipsPage = React.lazy(() => import("../ShipsPage/ShipsPage"));
const GoodsPage = React.lazy(() => import("../GoodsPage/GoodsPage"));
const EmployeesPage = React.lazy(() => import("../EmployeesPage/EmployeesPage"));
const TasksPage = React.lazy(() => import("../TasksPage/TasksPage"));
const MachineryPage = React.lazy(() => import("../MachineriesPage/MachineriesPage"));

export const useAddDialogContext = React.createContext({
    openAddDialog: false,
    setOpenAddDialog: (open: boolean) => {
    },
  }
);

export const useDeleteDialogContext = React.createContext({
    openDeleteDialog: false,
    setOpenDeleteDialog: (open: boolean) => {
    },
  }
);

export const useErrorContext = React.createContext({
    error: '' as string,
    setError: (message: string) => {
    },
  }
);

type PageParamsType = {
  pagePath: string;
};

function Home() {
  const {pagePath} = useParams<PageParamsType>();

  const [openAddDialog, setOpenAddDialog] = useState(false)
  const addDialogValue = {openAddDialog, setOpenAddDialog}

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const deleteDialogValue = {openDeleteDialog, setOpenDeleteDialog}

  const [error, setError] = useState<string>();
  const errorValue = {error, setError};

  const getPage = (pagePath: string) => {
    switch (pagePath) {
      case "dashboard":
        return <DashboardPage/>
      case "commesse":
        return <OrdersPage/>
      case "turni":
        return <ShiftsPage/>
      case "clienti":
        return <ClientsPage/>
      case "navi":
        return <ShipsPage/>
      case "prodotti":
        return <GoodsPage/>
      case "personale":
        return <EmployeesPage/>
      case "mansioni":
        return <TasksPage/>
      case "mezzi":
        return <MachineryPage/>
    }
  }
  return (
    <PageFrame>
      <useAddDialogContext.Provider value={addDialogValue}>
        <useDeleteDialogContext.Provider value={deleteDialogValue}>
          <useErrorContext.Provider value={errorValue}>
            {getPage(pagePath)}
          </useErrorContext.Provider>
        </useDeleteDialogContext.Provider>
      </useAddDialogContext.Provider>
    </PageFrame>
  );
}

export default Home;

