import React, {FC} from "react";
import {Box, Grid, Link, Typography} from "@mui/material";

type DetailsSectionProps = {
  sectionTitle: string,
  sectionTextContent?: string | number | null,
  contentRedirect?: string,
}

const DetailsSection: FC<DetailsSectionProps> = (
  {
    sectionTitle,
    sectionTextContent,
    children,
    contentRedirect,
  }
) => {
  if (Boolean(JSON.stringify(sectionTextContent))) {
    return (
      <Box>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4}>
            <Typography variant="caption" color="text.secondary"><em>{sectionTitle}</em></Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            {
              Boolean(contentRedirect)
                ? <Link
                  underline="hover"
                  key="1"
                  color="inherit"
                  href={contentRedirect}
                >
                  {sectionTextContent}
                </Link>
                : <Typography>{sectionTextContent}</Typography>
            }
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return (
      <Box>
        <Grid container alignItems="center">
          <Grid item xs={12} md={4}>
            <Typography variant="caption" color="text.secondary"><em>{sectionTitle}</em></Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography>{children}</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }
}


export default DetailsSection;