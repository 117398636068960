import React, {FC} from "react";
import {useTheme} from "@mui/material/styles";
import {Avatar, Grid, Typography} from "@mui/material";

type DatagridTitleProps ={
  title: string,
  icon: any,
}

const PageTitle : FC<DatagridTitleProps> = ({title, icon, }) => {
  const theme = useTheme();
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item>
        <Avatar
          sx={{
            height: theme => theme.spacing(8),
            width: theme => theme.spacing(8),
            bgcolor: 'primary.light',
          }}
          variant="rounded"
        >
          {icon}
        </Avatar>
      </Grid>
      <Grid item>
        <Typography variant="h3" sx={{fontWeight: 800}}>
          {title.charAt(0).toUpperCase() + title.slice(1)}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default PageTitle;