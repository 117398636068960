import React, {FC, useContext} from "react";
import {Box, Button, Grid} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  GridColumnMenuProps,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton
} from "@mui/x-data-grid";
import {useAddDialogContext} from "../Routes/Private/Home/Home";


function AddShiftToolbar(){

  const {openAddDialog, setOpenAddDialog} = useContext(useAddDialogContext);
  return (
    <Box p={1} sx={{display: 'flex'}}>
      <Grid container spacing={2}>
        <Grid item>
            <Button
              size="small"
              sx={{borderRadius: '8px'}}
              startIcon={<AddIcon/>}
              onClick={() => setOpenAddDialog(true)}
            >
              aggiungi
            </Button>
          </Grid>
        <Grid item>
          <GridToolbarDensitySelector sx={{borderRadius: '8px'}}/>
        </Grid>
      </Grid>
    </Box>
  );
}


export default AddShiftToolbar;