import React, {FC, useContext, useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {
  Box, Button,
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  LinearProgress,
  Typography,
  useMediaQuery, Fade, DialogTitle
} from "@mui/material";
import {useAddDialogContext} from "../Routes/Private/Home/Home";

type AddDialogProps = {
  title: string,
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
  loading?: boolean,
  onClose?: () => void,
  onDialogOpen?: () => void,
}

const AddDialog: FC<AddDialogProps> = (
  {
    title,
    handleSubmit,
    loading,
    children,
    onClose,
    onDialogOpen
  }) => {

  const theme = useTheme();
  const {openAddDialog, setOpenAddDialog} = useContext(useAddDialogContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    setOpenAddDialog(false)
    if (onClose) onClose();
  }

  useEffect(() => {
    if (onDialogOpen) onDialogOpen();
  }, []);

  return (
    <Dialog
      open={openAddDialog}
      onClose={handleClose}
      fullWidth
      scroll="paper"
      disableEscapeKeyDown
      onBackdropClick={onClose}
      TransitionComponent={Fade}
      fullScreen={isMobile}
    >
      {loading && <LinearProgress color="primary"/>}
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <DialogTitle>
          <Typography variant="h6">{title}</Typography>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            {children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleClose}>annulla</Button>
          <Button
            color="primary"
            type="submit"
          >
            Crea
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}

export default AddDialog;