import {
  Badge,
  Box,
  Breadcrumbs, Card, Checkbox, Chip,
  Container, FormControl, FormControlLabel, FormGroup,
  Grid, IconButton, InputAdornment, InputLabel,
  Link, MenuItem, Select,
  Typography, useMediaQuery,
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {useAddDialogContext, useDeleteDialogContext, useErrorContext} from "../Home/Home";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import PageTitle from "../../../DatagridComponents/DatagridTitle";
import SyncButton from "../../../SyncButton/SyncButton";
import TextField from '@mui/material/TextField';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import {useTheme} from "@mui/material/styles";
import {getDate} from "../../../../utils/dateHandler";
import {
  defaultOrders,
  defaultShifts,
  orders,
  shifts,
  userName
} from "../../../../services/services";
import {Commessa, TurnoLavoro} from "../../../../entities/interfaces";
import {DataGrid, GridColumns} from "@mui/x-data-grid";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import DeleteDialog from "../../../DatagridComponents/DatagridDeleteDialog";
import {CalendarPickerSkeleton} from "@mui/lab";
import AddDialog from "../../../DatagridComponents/DatagridAddDialog";
import NoRowsOverlay from "../../../DatagridComponents/DatagridNoRow";
import DatagridError from "../../../DatagridComponents/DatagridError";
import LoadingOverlay from "../../../DatagridComponents/DatagridLoading";
import AddShiftToolbar from "../../../AddShiftToolbar/AddShiftToolbar";

type PageParamsType = {
  pagePath: string;
};

const ShiftsPage = () => {

  const theme = useTheme();
  const history = useHistory();
  const {error, setError} = useContext(useErrorContext);
  const [loading, setLoading] = useState(false);
  const [updatedTime, setUpdatedTime] = useState("00:00");
  const {setOpenAddDialog} = useContext(useAddDialogContext);
  const {setOpenDeleteDialog} = useContext(useDeleteDialogContext);
  const {pagePath} = useParams<PageParamsType>();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedDay, setSelectedDay] = useState<Date | null>(new Date());
  const [Shifts, setShifts]: [TurnoLavoro[], (posts: TurnoLavoro[]) => void] = useState(defaultShifts);
  const [Orders, setOrders]: [Commessa[], (posts: Commessa[]) => void] = useState(defaultOrders);

  useEffect(() => {
    setLoading(true)
    shifts
      .get<TurnoLavoro[]>(`/TurniDelGiorno/${selectedDay.toISOString().split('T')[0]}`)
      .then(response => {
        setShifts(response.data);
        let dt = new Date();
        setUpdatedTime(
          ("0" + dt.getHours()).slice(-2)
          + ":"
          + ("0" + dt.getMinutes()).slice(-2)
        );
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    setLoading(true)
    shifts
      .get<TurnoLavoro[]>(`/TurniDelGiorno/${selectedDay.toISOString().split('T')[0]}`)
      .then(response => {
        setShifts(response.data);
        let dt = new Date();
        setUpdatedTime(
          ("0" + dt.getHours()).slice(-2)
          + ":"
          + ("0" + dt.getMinutes()).slice(-2)
        );
        setLoading(false);
      });
  }, [selectedDay]);

  const RenderMoreButton = (e: any) => {
    const handleMoreClick = () => {
      history.push(`/app/turni/${e.row.shiftId}`);
    };
    return (
      <IconButton
        onClick={handleMoreClick}
        size="small"
      >
        <OpenInNewOutlinedIcon/>
      </IconButton>
    );
  }

  const RenderEditButton = (e: any) => {
    const handleEditClick = () => {
      history.push(`/app/turni/${e.row.shiftId}`);
    };
    const handleDeleteClick = () => {
      shifts.get<TurnoLavoro[]>(`/Delete/${e.row.shiftId}/${userName}`)
        .then(() => {
          handleUpdate();
          setOpenDeleteDialog(false);
        })
    };

    return (
      <Grid container spacing={1} justifyContent="center">
        <Grid item>
          <IconButton
            onClick={handleEditClick}
            size="small"
          >
            <ModeEditOutlineOutlinedIcon/>
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton
            onClick={() => setOpenDeleteDialog(true)}
            size="small"
          >
            <DeleteIcon/>
          </IconButton>
        </Grid>
        <DeleteDialog handleDelete={handleDeleteClick} title="turno"/>
      </Grid>
    );
  }

  let rows = Shifts.map((el, index) => (
    {
      id: index,
      shiftId: el?.idTurnoLavoro,
      code: el?.codiceTurno,
      order: el?.commessa.codiceCommessa,
      description: el?.descrizioneTurno,
      state: el?.statoTurno,
    }
  ));
  const columns: GridColumns = [
    {
      field: 'shiftId',
      headerName: 'Id',
      width: 90,
      align: 'center',
      editable: false,
      headerAlign: 'center',
    },
    {
      field: 'code',
      headerName: 'Codice',
      width: 150,
      editable: false,
    },
    {
      field: 'order',
      headerName: 'Commessa',
      width: 150,
      editable: false,
    },
    {
      field: 'description',
      headerName: 'Descrizione',
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'more',
      headerName: 'Altro',
      description: 'Dettagli',
      align: 'center',
      renderCell: RenderMoreButton,
      width: 90,
      editable: false,
      sortable: false,
      headerAlign: 'center',
    },
    {
      field: 'edit',
      headerName: 'Modifica',
      description: 'Modifica, Elimina',
      align: 'center',
      renderCell: RenderEditButton,
      width: 110,
      editable: false,
      sortable: false,
      headerAlign: 'center',
    }
  ];
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/app/dashboard/"
    >
      App
    </Link>,
    <Typography
      key="2" color="text.primary"
    >
      {pagePath.charAt(0).toUpperCase() + pagePath.slice(1)}
    </Typography>,
  ];
  const handleUpdate = () => {
    setLoading(true)
    shifts
      .get<TurnoLavoro[]>(`/TurniDelGiorno/${selectedDay.toISOString().split('T')[0]}`)
      .then(response => {
        setShifts(response.data);
        let dt = new Date();
        setUpdatedTime(
          ("0" + dt.getHours()).slice(-2)
          + ":"
          + ("0" + dt.getMinutes()).slice(-2)
        );
        setLoading(false);
      });
  };
  const handleDateChange = (newValue: any) => {
    setSelectedDay(newValue);
  }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const newShift = {
      "descrizioneTurno": data.get('description'),
      "codiceTurno": data.get('code'),
      "dataTurno": selectedDay.toISOString(),
      "statoTurno": 1,
      "commessa": {
        "idCommessa": data.get('orderId'),
      }
    }
    shifts.put(`Add/${userName}`, newShift)
      .then(() => {
        setLoading(false);
        setOpenAddDialog(false);
        handleUpdate();
      });
  };
  const handleDoubleClick = (e: any) => {
    history.push(`/app/turni/${e.row.shiftId}/edit`);
  }

  const getOrders = () => {
    setLoading(true);
    orders
      .get<Commessa[]>('/AllAttivi')
      .then(response => {
        setOrders(response.data);
        setLoading(false);
      });
  }

  return (
    <Container>
      <Grid container justifyContent="center" direction="column" spacing={2} py={16}>
        <Grid item>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small"/>}>
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <PageTitle title={pagePath} icon={<DateRangeOutlinedIcon/>}/>
        </Grid>
        <Grid item>
          <SyncButton updatedTime={updatedTime} onClick={handleUpdate}/>
        </Grid>
        <Grid item container spacing={2} xs>
          <Grid item xs="auto">
            <Card variant="outlined">
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                renderLoading={() => <CalendarPickerSkeleton/>}
                openTo="day"
                value={selectedDay}
                loading={loading}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} />}
                /*renderDay={(day, _value, DayComponentProps) => {
                  const hasShift = true;
                  return (
                    <Badge
                      key={day.toString()}
                      overlap="circular"
                      badgeContent={hasShift ? ' ' : undefined}
                    >
                      <PickersDay {...DayComponentProps} />
                    </Badge>
                  );
                }}*/
              />
            </Card>
          </Grid>
          <Grid item container xs direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h5" sx={{fontWeight: 600}}>
                Del {getDate(selectedDay.toString())}
              </Typography>
            </Grid>
            <Grid item xs>
              <div style={{height: "100%", width: "100%"}}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  getRowId={(row) => row.id}
                  error={error ? true : null}
                  autoHeight={isMobile}
                  disableSelectionOnClick
                  loading={loading}
                  onRowDoubleClick={handleDoubleClick}
                  components={{
                    Toolbar: AddShiftToolbar,
                    NoRowsOverlay: NoRowsOverlay,
                    ErrorOverlay: DatagridError,
                    LoadingOverlay: LoadingOverlay,
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <AddDialog
        title={"Nuovo Turno del " + getDate(selectedDay.toString())}
        handleSubmit={handleSubmit}
        loading={loading}
        onDialogOpen={getOrders}
      >
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Box px={1} pt={2}>
              <Typography component="span" variant="body2">
                Scegli la commessa
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Commesse</InputLabel>
              <Select
                id="orderId"
                name="orderId"
                label="Commessa"
              >
                {Orders.map((el, index) => (
                  <MenuItem value={el.idCommessa}>
                    <Chip size="small" label={el.codiceCommessa}/>
                  </MenuItem>
                ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <Box px={1} pt={2}>
              <Typography component="span" variant="body2">
                Dettagli
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                id="description"
                name="description"
                label="Descrizione"
                fullWidth
                multiline
                placeholder="Descrizione Turno"
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl fullWidth>
              <TextField
                id="code"
                name="code"
                label="Codice"
                placeholder="Codice del turno"
              />
            </FormControl>
          </Grid>
        </Grid>
      </AddDialog>
    </Container>
  );
}


export default ShiftsPage;
