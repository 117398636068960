import React, {useContext} from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Fade,
} from "@mui/material";
import {useDeleteDialogContext} from "../Routes/Private/Home/Home";

type DeleteDialogProps = {
  handleDelete: () => void,
  title: string,
}
const DeleteDialog: React.FC<DeleteDialogProps> = (
  {
    handleDelete,
    title
  }
) => {
  const {openDeleteDialog, setOpenDeleteDialog} = useContext(useDeleteDialogContext);

  return (
    <Dialog
      open={openDeleteDialog}
      onClose={() => setOpenDeleteDialog(false)}
      sx={{boxShadow: 3}}
      BackdropProps={{
        sx:{
          backgroundColor: 'rgba(0,0,0,0.2)',
        }
      }}
      onBackdropClick={() => setOpenDeleteDialog(false)}
      TransitionComponent={Fade}
      maxWidth="xl"
    >
      <DialogTitle>{"Elimina " + title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="delete-dialog-slide-description">
          Stai per eliminare questo elemento, continuare?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={() => setOpenDeleteDialog(false)}>annulla</Button>
        <Button
          color="error"
          onClick={handleDelete}
        >
          Elimina
        </Button>
      </DialogActions>
    </Dialog>
  )
}


export default DeleteDialog;