import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {
  Avatar,
  Box,
  Breadcrumbs, Button, Card, CardContent, Checkbox,
  Chip,
  Container, DialogActions, DialogContent, DialogContentText, Divider, FormControl, FormControlLabel, FormGroup,
  Grid,
  IconButton, InputAdornment, InputLabel,
  Link, MenuItem, Select, Skeleton,
  TextField,
  Typography, useMediaQuery,
} from "@mui/material";
import {useParams} from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DirectionsBoatFilledOutlinedIcon from '@mui/icons-material/DirectionsBoatFilledOutlined';
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {Commessa, Nave} from "../../../../entities/interfaces";
import axios from "axios";
import {DatePicker} from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import PageFrame from "../../../PageFrame/PageFrame";
import {defaultOrder, orders, ships, userName} from "../../../../services/services";
import OrdersPage from "./OrdersPage";
import DetailsSection from "../../../DetailsSection/DetailsSection";
import {getDate} from "../../../../utils/dateHandler";

type PageParamsType = {
  id: string;
  pagePath: string;
};

const ShipsInfoPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const {id, pagePath} = useParams<PageParamsType>();
  const [year, setYear] = React.useState<Date | null>(new Date());
  const [Order, setOrder]: [Commessa, (posts: Commessa) => void] = useState(defaultOrder);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    orders.get<Commessa>(`/FindById/${id}`)
      .then(response => {
        setOrder(response.data);
        setLoading(false);
      });

  }, []);
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/app/dashboard/"
    >
      App
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href={`/app/${pagePath}/`}
    >
      {pagePath.charAt(0).toUpperCase() + pagePath.slice(1)}
    </Link>,
    <Typography
      key="3" color="text.primary"
    >
      {loading
        ? <Skeleton animation="wave" width="30px"/>
        : Order?.codiceCommessa.charAt(0).toUpperCase() + Order?.codiceCommessa.slice(1)
      }
    </Typography>,
  ];

  const handleUpdate = () => {
    setLoading(true)
    orders.get<Commessa>(`/FindById/${id}`)
      .then(response => {
        setOrder(response.data);
        setLoading(false);
      });
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const newOrder = {
      "nome": data.get('shipName'),
    }
    orders.put(`Update/${newOrder.nome}/${userName}`, newOrder)
      .then(() => {
        setEditMode(false)
        handleUpdate();
      });
  };

  const handleEditMode = (event: any) => {
    setEditMode(!editMode);
  }

  return (
    <Container maxWidth="md">
      <Box component="form" noValidate onSubmit={handleSubmit}>
        <Grid container justifyContent="center" direction="column" spacing={2} py={16}>
          <Grid item>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small"/>}
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Grid>
          <Grid item container spacing={2} alignItems="center">
            <Grid item>
              <Avatar
                sx={{
                  height: theme => theme.spacing(8),
                  width: theme => theme.spacing(8),
                  bgcolor: 'primary.light',
                }}
                variant="rounded"
              >
                <DirectionsBoatFilledOutlinedIcon/>
              </Avatar>
            </Grid>
            <Grid item>
              {editMode
                ? <TextField
                  id="shipName"
                  name="shipName"
                  label="Nome"
                  defaultValue={Order?.codiceCommessa}
                  fullWidth={isMobile}
                  placeholder="Nome Nave"
                  autoComplete="shipName"
                  autoFocus
                  required
                />
                : <Typography variant="h3" sx={{fontWeight: 800}}>
                  {loading
                    ? <Skeleton animation="wave" width="200px"/>
                    : Order?.codiceCommessa
                  }
                </Typography>
              }
            </Grid>
            <Grid item>
              {editMode
                ? <IconButton
                  color="primary"
                  children={<SaveOutlinedIcon/>}
                  onClick={handleEditMode}
                  size="small"
                />
                : <IconButton
                  color="primary"
                  children={<ModeEditOutlineOutlinedIcon/>}
                  onClick={handleEditMode}
                  size="small"
                />
              }
            </Grid>
            <Grid item>
              {editMode
                ? <IconButton
                  children={<CloseIcon/>}
                  onClick={() => setEditMode(false)}
                  size="small"
                />
                : null}
            </Grid>
          </Grid>
          <Grid item>
            {editMode
              ? <Card variant="outlined">
                <CardContent>
                  <Grid container direction="column" py={4} spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        id="description"
                        name="description"
                        label="Descrizione"
                        defaultValue={Order?.descrizioneCommessa}
                        fullWidth
                        multiline
                        placeholder="Descrizione Nave"
                      />
                    </Grid>
                    <Box py={2}>
                      <Divider textAlign="left"><Typography variant="body2"
                                                            color="text.secondary">Stive</Typography></Divider>
                    </Box>
                  </Grid>
                </CardContent>
              </Card>
              : <Card variant="outlined">
                {loading
                  ? <CardContent>
                    <Grid container direction="column" spacing={1} py={2}>
                      <Grid item container spacing={1} pt={1}>
                        <Grid item xs={12} sm={6}>
                          <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                        width="180px"/></Typography>
                          <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                        width="180px"/></Typography>
                          <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={1} pt={1}>
                        <Grid item xs={12} sm={6}>
                          <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                        width="180px"/></Typography>
                          <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                        width="180px"/></Typography>
                          <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={1} pt={1}>
                        <Grid item xs={12} sm={6}>
                          <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                        width="180px"/></Typography>
                          <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                        width="180px"/></Typography>
                          <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={1} pt={1}>
                        <Grid item xs={12} sm={6}>
                          <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                        width="180px"/></Typography>
                          <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                        width="180px"/></Typography>
                          <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                  : <CardContent>
                    <Grid container direction="column" spacing={1} py={2}>
                      <Grid item container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <DetailsSection sectionTitle="Tipologia:" sectionTextContent={Order?.tipologiaCommessa ? "Subbordinata" :"Principale"}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DetailsSection sectionTitle="Descrizione:" sectionTextContent={Order?.descrizioneCommessa}/>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <DetailsSection sectionTitle="Data inizio:" sectionTextContent={getDate(Order?.dataInizio)}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DetailsSection sectionTitle="Data fine"
                                          sectionTextContent={Order?.statoCommessa ? "In corso" : getDate(Order?.dataFine)}/>
                        </Grid>
                      </Grid>
                      <Grid item container spacing={1}>
                        <Grid item xs={12} sm={6}>
                          <DetailsSection sectionTitle="Fatturazione">
                            {Order?.previstaFatturazione
                              ? <Chip variant="outlined" icon={<CheckIcon/>} color="success" size="small"
                                      label="Si"/>
                              :
                              <Chip variant="outlined" icon={<CloseIcon/>} color="error" size="small"
                                    label="No"/>
                            }
                          </DetailsSection>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DetailsSection
                            sectionTitle="Cliente:"
                            sectionTextContent={Order?.cliente?.ragioneSociale}
                            contentRedirect={`/app/commesse/${Order?.cliente?.ragioneSociale}`}
                          />
                        </Grid>
                      </Grid>
                      <Box py={2}>
                        <Divider textAlign="left"><Typography variant="body2"
                                                              color="text.secondary">Prodotti</Typography></Divider>
                      </Box>
                      <Grid item xs={12} sm={6}>
                        <DetailsSection sectionTitle="Prodotti:" sectionTextContent={"tbi"}/>
                      </Grid>
                      <Box py={2}>
                        <Divider textAlign="left"><Typography variant="body2" color="text.secondary">Turni di
                          lavoro</Typography></Divider>
                      </Box>
                      <Grid item xs={12} sm={6}>
                        <DetailsSection sectionTitle="Turni Futuri:" sectionTextContent={"tbi"}/>
                      </Grid>
                    </Grid>
                  </CardContent>
                }
              </Card>
            }
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default ShipsInfoPage;