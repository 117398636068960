// @ts-ignore
import {
  Route, Redirect, BrowserRouter as Router, Switch as DefaultSwitch, useLocation, useHistory
} from 'react-router-dom';
import React, {useContext, useEffect, useState} from 'react';
import DetailsPage from "../DetailsPage/DetailsPage";
const Login = React.lazy(() => import("./Public/login/login"));
const Landing = React.lazy(() => import("./Public/Landing/Landing"));
const Home = React.lazy(() => import("./Private/Home/Home"));
const AccountPage = React.lazy(() => import("./Private/AccountPage/AccountPage"));
const NoMatch = React.lazy(() => import("./NoMatch/NoMatch"));

type PrivateRouterType = {
  isLogged: boolean,
  path: string,
}

/*export const Auth = React.createContext(
  {
    isLogged: false as boolean,
    setIsLogged: (isLogged: boolean) => {
    },
  }
);
*/

const PrivateRoute: React.FC<PrivateRouterType> = (
  {
    isLogged,
    path,
    children
  }
) => {
  return (
    <Route path={path}>
      {isLogged ? children : <Redirect to="/login"/>}
    </Route>
  );
};


const Routes = () => {
  const [isLogged, setIsLogged] = useState(false);
  const authValue = {isLogged, setIsLogged};
  useEffect(() => {

  })
  return (
    <Router>
      <DefaultSwitch>
        <Route exact path="/">
          <Redirect to="/landing"/>
        </Route>
        <Route path="/login">
          <Login/>
        </Route>
        <Route path="/landing">
          <Landing/>
        </Route>
        <Route path="/app/:pagePath/:id/">
          <DetailsPage/>
        </Route>
        <Route path="/app/:pagePath/">
          <Home/>
        </Route>
        <Route path="/account">
          <AccountPage/>
        </Route>
        <Route path="*">
          <NoMatch/>
        </Route>
      </DefaultSwitch>
    </Router>
  );
}

export default Routes;
