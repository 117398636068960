import React, {FC, useContext, useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {
  Accordion, AccordionDetails, AccordionSummary,
  Avatar, AvatarGroup,
  Box,
  Breadcrumbs, Button, Card, CardContent, Checkbox,
  Chip, CircularProgress,
  Container, Divider, FormControl, FormControlLabel, FormGroup,
  Grid,
  IconButton, InputAdornment, InputLabel, LinearProgress,
  Link, MenuItem, Select, Skeleton,
  TextField,
  Typography, useMediaQuery,
} from "@mui/material";
import {useHistory, useParams} from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import {
  ApplicationUser,
  CommessaProdotto,
  Mansione,
  Mezzo,
  Personale,
  TurnoLavoro, TurnoLavoroDettaglio
} from "../../../../entities/interfaces";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import {DatePicker, TimePicker} from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';
import {
  defaultEmployees, defaultMachineries,
  defaultShift, defaultShiftDetails,
  defaultTasks, employees, machineries,
  shifts,
  ships,
  tasks,
  userName
} from "../../../../services/services";
import DetailsSection from "../../../DetailsSection/DetailsSection";
import {getDate} from "../../../../utils/dateHandler";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from "@mui/icons-material/Add";
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined';
import {DataGrid, GridColumns, GridRowsProp} from "@mui/x-data-grid";
import NoRowsOverlay from "../../../DatagridComponents/DatagridNoRow";
import DatagridError from "../../../DatagridComponents/DatagridError";
import LoadingOverlay from "../../../DatagridComponents/DatagridLoading";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {useAddDialogContext, useDeleteDialogContext} from "../Home/Home";
import SyncButton from "../../../SyncButton/SyncButton";
import AddDialog from "../../../DatagridComponents/DatagridAddDialog";

type PageParamsType = {
  id: string;
  pagePath: string;
};

const ShiftDetailsPage = () => {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [updatedTime, setUpdatedTime] = useState("00:00");
  const {id, pagePath} = useParams<PageParamsType>();
  const {setOpenDeleteDialog} = useContext(useDeleteDialogContext);
  const {openAddDialog, setOpenAddDialog} = useContext(useAddDialogContext);
  const [Shift, setShift]: [TurnoLavoro, (posts: TurnoLavoro) => void] = useState(defaultShift);
  const [startTime, setStartTime] = React.useState<Date | null>(new Date(0, 0, 0, 8));
  const [endTime, setEndTime] = React.useState<Date | null>(new Date(0, 0, 0, 12));
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    shifts.get<TurnoLavoro>(`/FindById/${id}`)
      .then(response => {
        setShift(response.data);
        let dt = new Date();
        setUpdatedTime(
          ("0" + dt.getHours()).slice(-2)
          + ":"
          + ("0" + dt.getMinutes()).slice(-2)
        );
        setLoading(false);
      });
  }, []);
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      href="/app/dashboard/"
    >
      App
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="/app/turni/"
    >
      {pagePath.charAt(0).toUpperCase() + pagePath.slice(1)}
    </Link>,
    <Typography
      key="3" color="text.primary"
    >
      {loading
        ? <Skeleton animation="wave" width="30px"/>
        : "Del " + getDate(Shift.dataTurno)
      }
    </Typography>,

  ];

  const RenderEditButton = (e: any) => {
    const handleEditClick = () => {
      history.push(`/app/turni/${e.row.shiftId}`);
    };
    const handleDeleteClick = () => {
      shifts.get<TurnoLavoro[]>(`/Delete/${e.row.shiftId}/${userName}`)
        .then(() => {
          handleUpdate();
          setOpenDeleteDialog(false);
        })
    };

    return (
      <IconButton
        onClick={() => setOpenDeleteDialog(true)}
        size="small"
      >
        <DeleteIcon/>
      </IconButton>
    );
  }

  const rows = [{
    id: 1,
    task: 'gruista',
    machinery: 'Gru',
    employee: 'Stefano Rossi',
    startTime: '8.00',
    endTime: '12.00',
  }];
  const columns: GridColumns = [
    {
      field: 'task',
      headerName: 'Mansione',
      width: 150,
      editable: false,
      flex: 1,
    },
    {
      field: 'machinery',
      headerName: 'Mezzo',
      width: 150,
      editable: false,
      flex: 1,
    },
    {
      field: 'employee',
      headerName: 'Personale',
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'startTime',
      headerName: 'Inizio turno',
      minWidth: 150,
      type: 'dateTime',
      flex: 1,
    },
    {
      field: 'endTime',
      headerName: 'Fine turno',
      minWidth: 150,
      type: 'dateTime',
      flex: 1,
    },
    {
      field: 'edit',
      headerName: 'Elimina',
      description: 'Elimina questo turno',
      align: 'center',
      renderCell: RenderEditButton,
      width: 90,
      editable: false,
      sortable: false,
      headerAlign: 'center',
    }
  ];

  const handleUpdate = () => {
    setLoading(true)
    shifts.get<TurnoLavoro>(`/FindById/${id}`)
      .then(response => {
        setShift(response.data);
        let dt = new Date();
        setUpdatedTime(
          ("0" + dt.getHours()).slice(-2)
          + ":"
          + ("0" + dt.getMinutes()).slice(-2)
        );
        setLoading(false);
        setOpenAddDialog(false);
      });
  }

  const handleAdd = () => {

  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);

    const newShift = Shift;
    const newShiftDetails: TurnoLavoroDettaglio = {
      idTurnoLavoroDettaglio: -1,
    };
    newShiftDetails.oraInizioTurno = JSON.stringify(data.get('startTime'));
    newShiftDetails.oraFineTurno = JSON.stringify(data.get('endTime'));
    newShift.turnoLavoroDettagli.push(newShiftDetails);
    console.log(newShift,newShiftDetails)
    shifts.put(`Update/${userName}`, newShift)
      .then(() => {
        setLoading(false);
        handleUpdate();
      });
  };

  const handleEditMode = (event: any) => {
    setEditMode(!editMode);
  }

  return (
    <Container>
      <Grid container justifyContent="center" direction="column" spacing={2} py={16}>
        <Grid item>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small"/>}
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Grid>
        <Grid item container spacing={2} alignItems="center">
          <Grid item>
            <Avatar
              sx={{
                height: theme => theme.spacing(8),
                width: theme => theme.spacing(8),
                bgcolor: 'primary.light',
              }}
              variant="rounded"
            >
              <DateRangeOutlinedIcon/>
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h3" sx={{fontWeight: 800}}>
              {loading
                ? <Skeleton animation="wave" width="200px"/>
                : getDate(Shift.dataTurno)
              }
            </Typography>
          </Grid>
          <Grid item>
            {editMode
              ? <IconButton
                color="primary"
                children={<SaveOutlinedIcon/>}
                onClick={handleEditMode}
                size="small"
              />
              : <IconButton
                color="primary"
                children={<ModeEditOutlineOutlinedIcon/>}
                onClick={handleEditMode}
                size="small"
              />
            }
          </Grid>
          <Grid item>
            {editMode
              ? <IconButton
                children={<CloseIcon/>}
                onClick={() => setEditMode(false)}
                size="small"
              />
              : null}
          </Grid>
        </Grid>
        <Grid item>
          <SyncButton updatedTime={updatedTime} onClick={handleUpdate}/>
        </Grid>
        <Grid item>
          {editMode
            ? <Card variant="outlined">
              <CardContent>
                <Grid container direction="column" py={4} spacing={1}>

                  <Box py={2}>
                    <Divider textAlign="left"><Typography variant="body2"
                                                          color="text.secondary">Stive</Typography></Divider>
                  </Box>
                </Grid>
              </CardContent>
            </Card>
            : <Card variant="outlined">
              {loading
                ? <CardContent>
                  <Grid container direction="column" spacing={1} py={2}>
                    <Grid item container spacing={1} pt={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                      width="180px"/></Typography>
                        <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                      width="180px"/></Typography>
                        <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1} pt={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                      width="180px"/></Typography>
                        <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                      width="180px"/></Typography>
                        <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1} pt={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                      width="180px"/></Typography>
                        <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                      width="180px"/></Typography>
                        <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={1} pt={1}>
                      <Grid item xs={12} sm={6}>
                        <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                      width="180px"/></Typography>
                        <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Typography color="text.secondary"> <Skeleton animation="wave"
                                                                      width="180px"/></Typography>
                        <Typography variant="h6"> <Skeleton animation="wave" width="200px"/></Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
                : <CardContent>
                  <Grid container direction="column" spacing={1} py={2}>
                    <Grid item container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <DetailsSection sectionTitle="Commessa:" sectionTextContent={Shift?.commessa?.codiceCommessa}
                                        contentRedirect={`/app/commesse/${Shift?.commessa?.idCommessa}`}/>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DetailsSection sectionTitle="Codice:" sectionTextContent={Shift?.codiceTurno}/>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DetailsSection sectionTitle="Descrizione:" sectionTextContent={Shift?.descrizioneTurno}/>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Box pt={2}>
                        <Accordion elevation={0} disableGutters>
                          <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Grid container alignItems="center" spacing={2}>
                              <Grid item sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                              }}>
                                <AccessTimeIcon fontSize="small"/>
                              </Grid>
                              <Grid item>
                                <Typography variant="h6">8.00 - 12.00</Typography>
                              </Grid>
                              <Grid item>
                                <AvatarGroup max={3}>
                                  <Avatar sx={{width: 24, height: 24}} alt="Remy Sharp"
                                          src="https://randomuser.me/api/portraits/men/54.jpg"/>
                                  <Avatar sx={{width: 24, height: 24}} alt="Travis Howard"
                                          src="https://randomuser.me/api/portraits/men/11.jpg"/>
                                  <Avatar sx={{width: 24, height: 24}} alt="Cindy Baker"
                                          src="https://randomuser.me/api/portraits/men/67.jpg"/>
                                </AvatarGroup>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <AddShiftDetailsForm Shift={Shift} handleUpdate={handleUpdate}/>
                            <ShiftTable loading={loading} rows={rows} columns={columns}/>
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Button
                        startIcon={
                          <AddIcon fontSize="large" color="primary"/>
                        }
                        disabled={loading}
                        onClick={() => setOpenAddDialog(true)}
                      >
                        Aggiungi
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
              }
            </Card>
          }
        </Grid>
      </Grid>
      <AddDialog title="Nuova dettaglio turno" handleSubmit={handleSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={12} md>
            <TimePicker
              value={startTime}
              onChange={(newValue) => setStartTime(newValue)}
              label="Ora inizio"
              minTime={new Date(0, 0, 0, 8)}
              maxTime={new Date(0, 0, 0, 12)}
              renderInput={(params: any) =>
                <TextField
                  id="startTime"
                  name="startTime"
                  fullWidth
                  helperText={null}
                  {...params}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md>
            <TimePicker
              value={endTime}
              onChange={(newValue) => setEndTime(newValue)}
              label="Ora Fine"
              maxTime={new Date(0, 0, 0, 12)}
              minTime={new Date(0, 0, 0, 8)}
              renderInput={(params: any) =>
                <TextField
                  id="endTime"
                  name="endTime"
                  fullWidth
                  helperText={null}
                  {...params}
                />
              }
            />
          </Grid>
        </Grid>
      </AddDialog>
    </Container>
  );
}

function ShiftTable(
  props: {
    rows?: GridRowsProp,
    columns?: GridColumns,
    loading: boolean,
    error?: boolean,
    onRowDoubleClick?: (e: any) => void,
  }
) {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item><Typography variant="caption">Turni del personale</Typography></Grid>
      <Grid item component="div" sx={{width: "100%"}}>
        <DataGrid
          rows={props.rows}
          columns={props.columns}
          getRowId={(row) => row.id}
          autoHeight
          error={props.error ? true : null}
          loading={props.loading}
          rowsPerPageOptions={[]}
          onRowDoubleClick={props.onRowDoubleClick}
          components={{
            NoRowsOverlay: NoRowsOverlay,
            ErrorOverlay: DatagridError,
            LoadingOverlay: LoadingOverlay,
            Pagination: null
          }}
        />
      </Grid>
    </Grid>
  );
}

function AddShiftDetailsForm(
  props: { Shift: TurnoLavoro, handleUpdate: () => void }
) {
  const [startTime, setStartTime] = React.useState<Date | null>(new Date(0, 0, 0, 8));
  const [endTime, setEndTime] = React.useState<Date | null>(new Date(0, 0, 0, 12));
  const [loading, setLoading] = useState(false);
  const [Tasks, setTasks]: [Mansione[], (posts: Mansione[]) => void] = useState(defaultTasks);
  const [Employees, setEmployees]: [Personale[], (posts: Personale[]) => void] = useState(defaultEmployees);
  const [Machineries, setMachineries]: [Mezzo[], (posts: Mezzo[]) => void] = useState(defaultMachineries);

  const getTasks = () => {
    setLoading(true);
    tasks
      .get<Mansione[]>('/AllAttivi')
      .then(response => {
        setTasks(response.data);
        setLoading(false);
      });
  }
  const getEmployees = () => {
    setLoading(true);
    employees
      .get<Personale[]>('/AllAttivi')
      .then(response => {
        setEmployees(response.data);
        setLoading(false);
      });
  }
  const getMachineries = () => {
    setLoading(true);
    machineries
      .get<Mezzo[]>('/AllAttivi')
      .then(response => {
        setMachineries(response.data);
        setLoading(false);
      });
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const newShift = props.Shift;

    shifts.put(`Update/${userName}`, newShift)
      .then(() => {
        setLoading(false);
        props.handleUpdate();
      });
  };

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item><Typography variant="caption">Aggiungi turno individuale</Typography></Grid>
      <Grid item container spacing={1} pb={2} alignItems="center" component="form" noValidate onSubmit={handleSubmit}>
        <Grid item xs={12} md>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Mansione</InputLabel>
            <Select
              id="task"
              name="task"
              label="Mansione"
              onOpen={getTasks}
            >
              {loading
                ? <LoadingPlaceholder/>
                : Tasks.map((el) => (
                  <MenuItem value={el.idMansione}>
                    <Chip size="small" label={el.codiceMansione}/>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Mezzo</InputLabel>
            <Select
              id="machineries"
              name="machineries"
              label="Mezzo"
              onOpen={getMachineries}
            >
              {loading
                ? <LoadingPlaceholder/>
                : Machineries.map((el) => (
                  <MenuItem value={el.idMezzo}>
                    <Chip size="small" label={el.codiceMezzo}/>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Personale</InputLabel>
            <Select
              id="employees"
              name="employees"
              label="Personale"
              onOpen={getEmployees}
            >
              {loading
                ? <LoadingPlaceholder/>
                : Employees.map((el) => (
                  <MenuItem value={el.idPersonale}>
                    <Chip size="small" label={el.nome}/>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md>
          <TimePicker
            value={startTime}
            onChange={(newValue) => setStartTime(newValue)}
            label="Ora inizio"
            minTime={new Date(0, 0, 0, 8)}
            maxTime={new Date(0, 0, 0, 12)}
            renderInput={(params: any) =>
              <TextField
                name="Ora inizio"
                fullWidth
                helperText={null}
                {...params}
              />
            }
          />
        </Grid>
        <Grid item xs={12} md>
          <TimePicker
            value={endTime}
            onChange={(newValue) => setEndTime(newValue)}
            label="Ora Fine"
            maxTime={new Date(0, 0, 0, 12)}
            minTime={new Date(0, 0, 0, 8)}
            renderInput={(params: any) =>
              <TextField
                name="Ora Fine"
                fullWidth
                helperText={null}
                {...params}
              />
            }
          />
        </Grid>
        <Grid item container xs={12} md={1} alignItems="center" justifyContent="center">
          <IconButton color="primary">
            <SaveAltOutlinedIcon color="inherit"/>
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

const LoadingPlaceholder = () => {
  return <Grid container alignItems="center" justifyContent="center" sx={{width: '100%', height: '8vh'}}>
    <Grid item>
      <CircularProgress/>
    </Grid>
  </Grid>
}

export default ShiftDetailsPage;
