import React from "react";
import {Chip} from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";

const SyncButton = (
  props: { updatedTime: string, onClick: () => void }
) => {
  return(
    <Chip
      icon={<SyncIcon/>}
      label={"Aggiornato alle " + props.updatedTime}
      onClick={props.onClick}
      sx={{borderRadius: '16px'}}
      size="small"
    />
);
}

export default SyncButton;

